//  Colors

// Brand guideline colors
$litter: #c25100;
$water: #0fa39e;
$green: #86c593;
$teal: #2cb5b5;
$pink: #eb97c0;
$red: #e9484a;
$navy: #06668c;

//Palettes

// Primary
$primaryMain: $navy;
$primaryContrastText: #fff;
$primaryTextDisabled: rgba(white, 0.5);

//Secondary
$secondaryMain: #06668c;
$secondaryContrastText: #fff;

// Text
$grey: rgba(0, 0, 0, 0.54);

//Other
$lightGrey: #f5f5f5;
$mediumGrey: #c3c3c3;

:export {
  primaryMain: $primaryMain;
  secondaryMain: $secondaryMain;
  primaryContrastText: $primaryContrastText;
  secondaryContrastText: $secondaryContrastText;
  primaryTextDisabled: $primaryTextDisabled;
  lightGrey: $lightGrey;
  mediumGrey: $mediumGrey;
  darkGrey: $grey;
  litter: $litter;
  green: $green;
  water: $water;
  teal: $teal;
  pink: $pink;
  red: $red;
}
