@use "standard.module.scss" as *;

$navDotsHeight: 20px;

.NavDots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.NavDot {
  height: $navDotsHeight;
  width: $navDotsHeight;
  border-radius: 50%;
  margin: 2px 5px 20px;
  transition: 0.2s;
  outline: none;
  border: solid 1px #c8c8c8;

  &--clickable {
    cursor: pointer;
  }

  &--active {
    background: black;
  }
}

.NavDot__inner {
  background: $primaryMain;
  transition: 0.2s ease;
  height: 100%;
  width: 100%;
  transform: scale(0);
  transform-origin: center;
  border-radius: 50%;
  border: none;

  &--active {
    transform: scale(1);
  }
}
