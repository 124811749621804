@use "./standard.module.scss" as *;

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.geovation-app {
  display: flex;
  flex-flow: column;
  height: 100vh;

  .hidden {
    display: none;
  }

  main {
    flex: 1 1 auto;
    position: relative; /* need this to position inner content */
    overflow: hidden;
    height: 100%;
  }

  footer {
    flex: 0 0 auto;
  }
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background: $primaryMain !important;
}
.firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: $primaryMain !important;
}

.body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.firebaseui-id-page-sign-in.mdl-shadow--2dp {
  box-shadow: none;
}
