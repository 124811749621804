@use "standard.module.scss" as *;

.geovation-map,
.map-filters-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filter-all-selected {
    background-color: $primaryMain !important;
    border: 4px solid $primaryMain !important;
    color: $primaryContrastText !important;
    margin-right: 10px;
  }

  .filter-all-unselected {
    background-color: $lightGrey !important;
    border: 4px solid $primaryMain !important;
    margin-right: 10px;
  }

  .filter-water-selected {
    color: $primaryContrastText !important;
    background-color: $water !important;
    border: 4px solid $water !important;
    margin-right: 10px;
  }

  .filter-water-unselected {
    background-color: $lightGrey !important;
    border: 4px solid $water !important;
    margin-right: 10px;
  }

  .filter-litter-selected {
    color: $primaryContrastText !important;
    background-color: $litter !important;
    border: 4px solid $litter !important;
    margin-right: 10px;
  }

  .filter-litter-unselected {
    background-color: $lightGrey !important;
    border: 4px solid $litter !important;
    margin-right: 10px;
  }

  .marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    background-size: cover;
    background-color: white;
    width: 40px !important;
    height: 40px !important;
  }

  .marker-water {
    border: 4px solid $water;
  }

  .marker-litter {
    border: 4px solid $litter;
  }

  .marker:hover {
    width: 50px !important;
    height: 50px !important;
  }

  .map {
    width: 100%;
    height: 100%;
    margin-bottom: calc(75px + env(safe-area-inset-bottom));
  }
}
